import { useAuth0 } from "@auth0/auth0-react";

import { Component1 } from "./HomePage/Component1";
import { Component2 } from "./HomePage/Component2";
import { Component3 } from "./HomePage/Component3";
import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";

export const HomeScreen = () => {
  const { user } = useAuth0();
  // return <LandingPage />;
    const navigate = useNavigate();

  if (user){
    navigate("/menus", { replace: true })
  } else {
    window.location.href = 'https://mychefsbase.com'
  }

  return (
    <>
      <LandingPage2 />
      <Component1 />
      <Component2 />
      <Component3 />
    </>
  );
};

const logoStyle = {
  width: "80px",
  maxHeight: "50px",
  height: "auto",
  cursor: "pointer",
};

const LandingPage2 = () => {
  const handleScroll = () => {
    window.scrollTo({
      top: window.scrollY + 700, // Adjust the 700 value to scroll down by a different amount
      behavior: "smooth",
    });
  };

  const handleHomeClick = () => {
    window.scrollTo({
      top: 0, // Scroll to the top of the page
      behavior: "smooth",
    });
  };

  const handleContactClick = () => {
    window.scrollTo({
      top: document.body.scrollHeight, // Scroll to the bottom of the page
      behavior: "smooth",
    });
  };
  const { user, loginWithRedirect } = useAuth0();
  const navigate = useNavigate();

  return (
    <Div>
      <NavDiv>
        <NavItem onClick={handleHomeClick}>Home</NavItem>
        <NavItem onClick={handleScroll}>How it Works</NavItem>
        <NavItem onClick={handleContactClick}>Contact</NavItem>
        <Div6
          onClick={() =>
            user ? navigate("/menus", { replace: true }) : loginWithRedirect()
          }
        >
          Sign In
        </Div6>
      </NavDiv>
      <LogoDiv>
        <Title>MyChefsbase</Title>
        <img src={"/MCB_logo.png"} alt="MyChefsbase Logo" style={logoStyle} />
      </LogoDiv>
    </Div>
  );
};

const Div = styled.div`
  background-color: #faf4ec;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
  @media (max-width: 1400px) {
    flex-wrap: wrap;
  }
`;

const NavDiv = styled.div`
  display: flex;
  gap: 20px;
  font-size: 14px;
  color: var(--Colour-Main-Blue-500, #505f98);
  font-weight: 400;
  cursor: pointer;
  white-space: nowrap;
  line-height: 171%;
  @media (max-width: 991px) {
    white-space: initial;
  }
`;

const NavItem = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  font-family: Roboto, sans-serif;
`;

const Div6 = styled.div`
  font-feature-settings: "clig" off, "liga" off;
  border-radius: 25px;
  background-color: #ebce9d; /* Updated background color */
  color: black;
  text-align: center;
  justify-content: center;
  padding: 7px 46px;
  font: 500 12px/150% Roboto, sans-serif;
  @media (max-width: 991px) {
    padding: 0 20px;
  }
`;

const LogoDiv = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
`;

const Title = styled.div`
  font-size: 24px; // Make the text big
  color: black; // Make the text black
  font-weight: bold; // Make the text bold
  font-family: Roboto, sans-serif;
`;
